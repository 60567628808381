import { getHeader, assistantUrl } from '@/config/config';
import axios from 'axios';

export const createAssistant = async assistant => {
  const response = await axios.post(assistantUrl, assistant, {
    headers: getHeader(),
  });

  return response.data;
};

export const editAssistant = async (assistantId, assistantData) => {
  const response = await axios.put(`${assistantUrl}/${assistantId}`, assistantData, {
    headers: getHeader(),
  });

  return response.data;
};
export const deleteAssistant = assistantId => {
  return axios.delete(`${assistantUrl}/${assistantId}`, {
    headers: getHeader(),
  });
};
