<template>
  <v-container v-if="!showDontHaveService" id="recepcionista">
    <v-row align="center" justify="center">
      <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="transparent">
          <h1 class="headline">{{ $t('recepcionista.recepcionistas') }}</h1>
          <v-spacer />
          <v-dialog v-model="dialog" max-width="600" scrollable persistent>
            <template v-slot:activator="{ on }">
              <v-btn
                data-test="new-recepcionist"
                color="primary"
                rounded
                depressed
                dark
                v-on="on"
                @click="reset"
              >
                {{$t('recepcionista.crear') }}
              </v-btn>
            </template>
            <v-card>
              <v-toolbar flat>
                <h2 class="title">{{ formTitle }}</h2>
                <v-spacer />
                <v-btn icon @click="dialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar>
              <v-card-text class="pa-4">
                <v-container class="pa-0" grid-list-md>
                  <v-layout wrap>
                    <v-flex xs12 sm6>
                      <v-text-field
                        data-test="settings-receptionist-input-name"
                        v-model="firstName"
                        autofocus
                        outlined
                        dense
                        :error-messages="firstNameValidationError"
                        :label="$t('patient.first_name')"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        data-test="settings-receptionist-input-surname"
                        v-model="lastName"
                        outlined
                        dense
                        :label="$t('patient.last_name')"
                        :error-messages="lastNameValidationError"
                        type="text"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        data-test="settings-receptionist-input-document"
                        v-model="documentId"
                        :error-messages="documentIdValidationError"
                        outlined
                        dense
                        :label="es_dingdoc ? $t('patient.cedula_or_passport') : $t('patient.document_id')"
                      />
                    </v-flex>
                    <v-flex xs12 sm6>
                      <v-text-field
                        data-test="settings-receptionist-input-email"
                        v-model="emailAddress"
                        :error-messages="emailValidationError"
                        outlined
                        dense
                        type="mail"
                        :label="$t('message.email')"
                        :disabled="disabled"
                      />
                    </v-flex>
                    <v-flex v-if="showCompaniesSelector" xs12 sm6>
                      <CompaniesSelector
                        :company="currentCompany"
                        :disabled="disabled"
                        @companySelected="companySelected"
                      />
                    </v-flex>
                    <v-flex v-if="showProfessionalsSelector" xs12 sm6>
                      <ProfessionalsSelector
                        :company-id="selectedCompany"
                        :professional="currentProfessional"
                        :validation-error="selectedProfessionalValidationError"
                        :disabled="disabled"
                        :dense="true"
                        @professionalSelected="professionalSelected"
                      />
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="text-right">
                  <v-btn
                    data-test="settings-receptionist-button-cancel"
                    color="grey darken-1"
                    class="mr-2"
                    text
                    @click="reset"
                    @click.native="close"
                  >
                    {{$t('common.cancel')}}
                  </v-btn>
                  <v-btn
                    data-test="settings-receptionist-button-save"
                    :loading="submittingForm"
                    :disabled="submittingForm"
                    depressed
                    color="primary"
                    @click.native="save"
                  >
                    {{ btnTxt }}
                  </v-btn
                  >
                </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-card>
          <v-card-text class="pa-0">
            <v-data-table
              :headers="headers"
              :items="recepcionistas"
              :hide-default-footer="recepcionistas.length < 10"
              class="elevation-1"
              :footer-props="{
                itemsPerPageText: $t('datatable.rows_per_page'),
                itemsPerPageAllText: $t('datatable.all'),
                pageText: '',
              }"
            >
              <template v-slot:item="props">
                <tr>
                  <td width="50%">{{ props.item.name }} {{ props.item.surname }}</td>
                  <td width="40%">{{ props.item.email }}</td>
                  <td width="10%">
                    <v-btn icon color="grey" @click="editItem(props.item)">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn icon color="grey" @click="deleteItem(props.item)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>

              <template slot="no-data">{{ $t('recepcionista.sin_recepcionista') }}</template>
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-dialog v-model="dialog_delete.dialog" dialog_delete max-width="500px">
          <v-card>
            <v-toolbar flat>
              <h2 class="title">{{ $t('recepcionista.eliminar_titulo') }}</h2>
              <v-spacer />
              <v-btn icon @click="dialog_delete.dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <p>{{ $t('recepcionista.eliminar_texto') }}</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn color="grey darken-1" text @click.native="dialog_delete.dialog = false">
                {{$t('common.cancel') }}
              </v-btn>
              <v-btn
                color="red darken-1"
                :loading="isDeleting"
                :disabled="isDeleting"
                dark
                depressed
                @click.native="
                  dialog_delete.dialog = false;
                  deleteAssistant();
                "
              >
                {{ $t('common.delete') }}
              </v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </v-row>
  </v-container>
  <v-container v-else>
    <no-service-granted view="recepcionista" />
  </v-container>
</template>

<script>
import { haveEprescriptionActive } from '@/utils';
import NoServiceGranted from '@/components/common/NoServiceGranted.vue';
import Alerts from '@/mixins/Alerts';
import {
  getReceptionistsUrl,
  getHeader,
  nameEnv,
  editReceptionistUrl,
  createReceptionistUrl,
  deleteReceptionistUrl,
} from '@/config/config';
import CompaniesSelector from '@/components/Companies/CompaniesSelector';
import ProfessionalsSelector from '@/components/professionals/ProfessionalsSelector';
import { authUserService } from '@/services/auth-user';
import { useAssistantsManagement } from '@/use/Assistants/useAssitantsManagement';

export default {
  components: {
    ProfessionalsSelector,
    CompaniesSelector,
    NoServiceGranted,
  },
  mixins: [Alerts],
  setup() {
    const {
      selectedCompany,
      selectedProfessional,
      firstName,
      firstNameFormatted,
      lastName,
      lastNameFormatted,
      emailAddress,
      emailFormatted,
      documentId,
      documentIdFormatted,
      submittingForm,
      submittedWithErrors,
      firstNameValidationError,
      lastNameValidationError,
      emailValidationError,
      documentIdValidationError,
      selectedProfessionalValidationError,
      resetForm,
      validateAssistantForm,
      submitCreateAssistantForm,
      submitEditAssistantForm,
      submitDeleteAssistant,
      fillAssistantForm,
    } = useAssistantsManagement();

    return {
      firstName,
      firstNameFormatted,
      lastName,
      lastNameFormatted,
      emailAddress,
      emailFormatted,
      documentId,
      documentIdFormatted,
      submittingForm,
      submittedWithErrors,
      firstNameValidationError,
      lastNameValidationError,
      emailValidationError,
      documentIdValidationError,
      selectedCompany,
      selectedProfessional,
      selectedProfessionalValidationError,
      resetForm,
      validateAssistantForm,
      submitCreateAssistantForm,
      submitEditAssistantForm,
      submitDeleteAssistant,
      fillAssistantForm,
    };
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      isDeleting: false,
      authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
      dialog: false,
      recepcionistas: [],
      headers: [
        { text: this.$t('common.name'), value: 'name' },
        { text: this.$t('message.email'), value: 'email' },
        {
          text: this.$t('common.actions'),
          value: '',
          sortable: false,
          class: 'text-center',
        },
      ],
      editedIndex: -1,
      editedId: null,
      editedUserId: null,
      dialog_delete: {
        name: '',
        dialog: false,
      },
      es_dingdoc: nameEnv === 'dingdoc',
      showDontHaveService: false,
      currentProfessional: null,
      currentCompany: null,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? this.$t('recepcionista.crear') : this.$t('recepcionista.editar');
    },

    btnTxt() {
      return this.editedIndex === -1 ? this.$t('common.create') : this.$t('common.save');
    },

    showCompaniesSelector() {
      return authUserService.isAdminRol() && !authUserService.isCompanyAdminRol();
    },

    showProfessionalsSelector() {
      return authUserService.isAdminRol() || authUserService.isCompanyAdminRol();
    },

    disabled() {
      return this.editedIndex > -1;
    },

    isProfessional() {
      return authUserService.isProfessionalRol();
    },
  },
  created() {
    if (haveEprescriptionActive()) {
      this.showDontHaveService = true;
    } else {
      this.loadAssistants();
    }

    if (this.isProfessional) {
      this.selectedProfessional = this.authUser.user.docline_api_id;
    }
  },

  methods: {
    loadAssistants() {
      this.$http
        .post(getReceptionistsUrl, { user_id: this.authUser.id, accion: 'index' }, { headers: getHeader() })
        .then(response => {
          this.recepcionistas = response.data.recepcionistas;
          this.recepcionistas.sort(function(a, b) {
            return new Date(b.created_at) - new Date(a.created_at);
          });
        });
    },

    editItem(item) {
      this.editedIndex = this.recepcionistas.indexOf(item);
      this.fillAssistantForm(item);
      this.editedId = item.id;
      this.editedUserId = item.user_id;
      this.currentProfessional = {
        ...item.professional,
        publicName: item.professional.name,
      };
      this.currentCompany = item.company;
      this.dialog = true;
    },

    reset() {
      if (!this.isProfessional) {
        this.currentProfessional = null;
        this.currentCompany = null;
        this.selectedCompany = null;
        this.selectedProfessional = null;
      }
      this.resetForm();
      this.editedIndex = -1;
      this.editedId = null;
    },

    deleteItem(item) {
      this.editedIndex = this.recepcionistas.indexOf(item);
      this.editedId = item.id;
      this.dialog_delete.dialog = true;
    },

    async deleteFromAdmin() {
      try {
        this.isDeleting = true;
        await this.submitDeleteAssistant(this.recepcionistas[this.editedIndex].docline_api_id);
        this.loadAssistants();
      } catch (e) {
        this.toastError(this.$t('errors.try_again'));
      } finally {
        this.isDeleting = false;
      }
    },
    deleteFromProfessional() {
      this.isDeleting = true;
      this.$http
        .post(
          deleteReceptionistUrl,
          {
            user_id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
            indice: this.editedIndex,
            accion: 'eliminar',
            recepcionista: this.editedId,
          },
          { headers: getHeader() }
        )
        .then(() => {
          this.loadAssistants();
        })
        .catch(() => {
          this.toastError(this.$t('errors.try_again'));
        })
        .finally(() => {
          this.isDeleting = false;
        });
    },

    deleteAssistant() {
      if (this.isProfessional) {
        this.deleteFromProfessional();
      } else {
        this.deleteFromAdmin();
      }
    },

    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedIndex = -1;
      }, 300);
    },

    async saveFromAdmin() {
      try {
        this.validateAssistantForm();
        if (this.submittedWithErrors) {
          return;
        }

        if (this.editedIndex === -1) {
          await this.submitCreateAssistantForm();
        } else {
          await this.submitEditAssistantForm();
        }

        this.close();
        this.loadAssistants();
      } catch (e) {
        this.toastError(this.$t('errors.try_again'));
      }
    },

    saveFromProfessional() {
      this.validateAssistantForm();
      if (this.submittedWithErrors) {
        return;
      }

      this.submittingForm = true;

      const endPoint = this.editedIndex > -1 ? editReceptionistUrl : createReceptionistUrl;
      this.$http
        .post(
          endPoint,
          {
            user_id: JSON.parse(window.localStorage.getItem('auth_ehealth'))['id'],
            accion: '',
            indice: this.editedIndex,
            recepcionista: {
              user_id: this.editedUserId,
              id: this.editedId,
              name: this.firstNameFormatted,
              surname: this.lastNameFormatted,
              email: this.emailFormatted,
              id_document: this.documentIdFormatted,
            },
          },
          { headers: getHeader() }
        )
        .then(() => {
          this.close();
          this.loadAssistants();
        })
        .catch(() => {
          this.toastError(this.$t('errors.try_again'));
        })
        .finally(() => {
          this.submittingForm = false;
        });
    },

    save() {
      if (this.isProfessional) {
        this.saveFromProfessional();
      } else {
        this.saveFromAdmin();
      }
    },

    companySelected(selected) {
      this.selectedCompany = selected;
    },

    professionalSelected(selected) {
      this.selectedProfessional = selected;
    },
  },
};
</script>
