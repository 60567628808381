<template>
  <div class="companies-selector-network-error__container">
    <div class="mb-4">{{ $t('companies_selector.network_error') }}</div>
    <div>
      <v-btn
        depressed
        color="primary"
        :disabled="loading"
        @click="retry"
      >
        <v-icon left>
          mdi-refresh
        </v-icon>
        {{ $t('companies_selector.retry') }}
      </v-btn>
    </div>
  </div>
</template>

<script>
export default {
  props: ['retry', 'loading']
}
</script>

<style>
.companies-selector-network-error__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
