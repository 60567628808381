<template>
  <div>
    <v-autocomplete
      v-model="currentCompany"
      :items="companies"
      :loading="isLoadingCompanies"
      outlined
      dense
      hide-selected
      item-text="name"
      clearable
      :placeholder="$t('empresa.empresa')"
      :label="isNetworkError ? $t('companies_selector.network_error_short') : $t('empresa.empresa')"
      item-value="id"
      :disabled="disabled"
      @change="companySelected"
    >
      <template slot="label">
        <div class="d-flex align-baseline">
          <template v-if="isNetworkError">
            <v-icon color="error" small class="mr-2">
              mdi-alert-circle
            </v-icon>
            <span class="error--text text-body-1">
              {{ $t('companies_selector.network_error_short') }}
            </span>
          </template>
          <template v-else>
            <span>{{ $t('empresa.empresa') }}</span>
          </template>
        </div>
      </template>
      <template slot="item" slot-scope="{ item }">
        <div class="py-1">
          <span class="primary--text text-body-1">
            {{ item.name }}
          </span>
          <div>
            <span v-for="specialty in item.specialties" :key="specialty.id" class="caption mr-2">{{
              specialty.name
            }}</span>
          </div>
        </div>
      </template>
      <template slot="no-data">
        <div class="companies-selector-no-data__container primary--text text-body-1 px-4 py-2">
          <CompaniesSelectorNetworkError
            v-if="isNetworkError"
            :retry="retrieveCompanies"
            :loading="isLoadingCompanies"
          />
          <div v-else>
            <v-progress-circular v-if="isLoadingCompanies" :width="3" color="primary" indeterminate class="mr-4" />
            <span>{{
              isLoadingCompanies
                ? $t('companies_selector.loading_companies')
                : $t('companies_selector.not_found_companies')
            }}</span>
          </div>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import { useCompaniesSelector } from '@/use/Companies/useCompaniesSelector';
import CompaniesSelectorNetworkError from '@/components/Companies/CompaniesSelectorNetworkError';

export default {
  components: {
    CompaniesSelectorNetworkError,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    company: {
      type: Object,
      default: null,
    },
  },
  setup(props) {
    const {
      companies,
      currentCompany,
      isLoadingCompanies,
      isLoadingCurrentCompany,
      isNetworkError,
      retrieveCompanies,
      addCompanyIfNotExists,
    } = useCompaniesSelector({ props });

    retrieveCompanies();

    return {
      companies,
      currentCompany,
      isLoadingCompanies,
      isLoadingCurrentCompany,
      isNetworkError,
      retrieveCompanies,
      addCompanyIfNotExists
    };
  },

  watch: {
    company(val) {
      if (val) {
        this.addCompanyIfNotExists(val);
        this.currentCompany = val.id;
      } else {
        this.currentCompany = null;
      }
    },
  },

  methods: {
    companySelected() {
      this.$emit('companySelected', this.currentCompany);
    },
  },
};
</script>

<style>
.companies-selector-no-data__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
