import { ref } from 'vue';

import { computed } from 'vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required, email } from '@vuelidate/validators';
import {createUser, getUserByEmail} from '@/services/users';
import { createAssistant, editAssistant, deleteAssistant } from '@/services/assistants';
import { isValidCif, isValidNif } from 'nif-dni-nie-cif-validation';

export function useAssistantsManagement() {
  const submittedWithErrors = ref(false);
  const submittingForm = ref(false);
  const isNetworkError = ref(false);

  const assistantId = ref(null);
  const firstName = ref(null);
  const lastName = ref(null);
  const emailAddress = ref(null);
  const documentId = ref(null);
  const selectedProfessional = ref(null);
  const selectedCompany = ref(null);

  const nifNieCifValidation = document => {
    return isValidNif(document) || isValidCif(document);
  };

  const validationRules = computed(() => ({
    firstName: {
      required: helpers.withMessage('Falta el nombre del asistente', required),
      $autoDirty: true,
    },
    lastName: {
      required: helpers.withMessage('Faltan los apellidos del asistente', required),
      $autoDirty: true,
    },
    emailAddress: {
      required: helpers.withMessage('Falta el email del asistente', required),
      email: helpers.withMessage('El email es inválido', email),
      $autoDirty: true,
    },
    documentId: {
      required: helpers.withMessage('Falta el DNI, NIE o pasaporte del asistente', required),
      nifNieCifValidation: helpers.withMessage(
        'El documento de identidad es inválido',
        nifNieCifValidation
      ),
      $autoDirty: true,
    },
    selectedProfessional: {
      required: helpers.withMessage('Debe seleccionar un profesional', required),
      $autoDirty: true,
    },
  }));

  const v$ = useVuelidate(validationRules, { firstName, lastName, emailAddress, documentId, selectedProfessional });

  const validateAssistantForm = () => {
    submittedWithErrors.value = false;
    v$.value.$touch();
    submittedWithErrors.value = v$.value.$invalid;
  };

  const resetForm = () => {
    assistantId.value = null;
    firstName.value = null;
    lastName.value = null;
    emailAddress.value = null;
    documentId.value = null;
  };

  const firstNameFormatted = computed(() => {
    if (!firstName.value) {
      return null;
    }

    return firstName.value.trim();
  });

  const lastNameFormatted = computed(() => {
    if (!lastName.value) {
      return null;
    }

    return lastName.value.trim();
  });

  const emailFormatted = computed(() => {
    if (!emailAddress.value) {
      return null;
    }

    return emailAddress.value.trim();
  });

  const documentIdFormatted = computed(() => {
    if (!documentId.value) {
      return null;
    }

    return documentId.value.trim();
  });

  const createAssistantUser = async () => {
    submittingForm.value = true;
    const { id } = await createUser({
      firstName: firstNameFormatted.value,
      lastName: lastNameFormatted.value,
      email: emailFormatted.value,
    });

    return id;
  };

  const submitCreateAssistantForm = async () => {
    submittingForm.value = true;
    try {
      const userId = await createAssistantUser();
      await createAssistant({
        userId: userId,
        professionalId: selectedProfessional.value,
        firstName: firstNameFormatted.value,
        lastName: lastNameFormatted.value,
        idNumber: documentIdFormatted.value,
      });
      resetForm();
    } catch (e) {
      if (e.response?.data?.error?.code === 'user_already_exists') {
        const uuid = await getUserByEmail(emailFormatted.value);
        await createAssistant({
          userId: uuid,
          professionalId: selectedProfessional.value,
          firstName: firstNameFormatted.value,
          lastName: lastNameFormatted.value,
          idNumber: documentIdFormatted.value,
        });
      } else {
        throw new Error(e);
      }
    } finally {
      submittingForm.value = false;
    }
  };

  const submitEditAssistantForm = async () => {
    submittingForm.value = true;
    try {
      await editAssistant(assistantId.value, {
        firstName: firstNameFormatted.value,
        lastName: lastNameFormatted.value,
        idNumber: documentIdFormatted.value,
      });
      resetForm();
    } catch (e) {
      throw new Error(e);
    } finally {
      submittingForm.value = false;
    }
  };

  const submitDeleteAssistant = async assistantId => {
    await deleteAssistant(assistantId);
  };

  const fillAssistantForm = assistant => {
    assistantId.value = assistant.docline_api_id;
    selectedProfessional.value = assistant.professional.uuid;
    firstName.value = assistant.name;
    lastName.value = assistant.surname;
    emailAddress.value = assistant.email;
    documentId.value = assistant.socialSecurityNumber ?? assistant.id_document;
  };

  const firstNameValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.firstName.$errors.map(error => error.$message) : null;
  });

  const lastNameValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.lastName.$errors.map(error => error.$message) : null;
  });

  const emailValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.emailAddress.$errors.map(error => error.$message) : null;
  });

  const documentIdValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.documentId.$errors.map(error => error.$message) : null;
  });

  const selectedProfessionalValidationError = computed(() => {
    return submittedWithErrors.value ? v$.value.selectedProfessional.$errors.map(error => error.$message) : null;
  });

  return {
    firstName,
    firstNameFormatted,
    lastName,
    lastNameFormatted,
    emailAddress,
    emailFormatted,
    documentId,
    documentIdFormatted,
    selectedCompany,
    selectedProfessional,
    isNetworkError,
    v$,
    submittingForm,
    submittedWithErrors,
    resetForm,
    validateAssistantForm,
    submitCreateAssistantForm,
    submitEditAssistantForm,
    submitDeleteAssistant,
    fillAssistantForm,
    deleteAssistant,
    firstNameValidationError,
    lastNameValidationError,
    emailValidationError,
    documentIdValidationError,
    selectedProfessionalValidationError,
  };
}
