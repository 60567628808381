import {ref} from 'vue';
import {getCompanyUrl, getHeader} from '@/config/config';

import axios from 'axios';
import {getCompanies} from "@/views/users/users.api";

export function useCompaniesSelector({ props }) {
  const companies = ref([]);
  const currentCompany = ref(null);

  const isLoadingCompanies = ref(false);
  const isLoadingCurrentCompany = ref(false);
  const isNetworkError = ref(false);

  const fetchCurrentCompany = async companyId => {
    const url = new URL(`${getCompanyUrl}/${companyId}`);

    const raw = await axios.get(url.toString(), {
      headers: getHeader(),
    });
    return raw.data;
  };

  const addCompanyIfNotExists = company => {
    const exists = companies.value.some(el => el.id === company.id);

    if (!exists) {
      companies.value.push(company);
    }
  };

  const retrieveCompanies = async () => {
    try {
      isNetworkError.value = false;
      isLoadingCompanies.value = true;
      companies.value = await getCompanies();

      if (props.company) {
        addCompanyIfNotExists(props.company);
        currentCompany.value = props.company.id;
      }
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingCompanies.value = false;
    }
  };

  const retrieveCurrentCompany = async (companyId) => {
    try {
      isNetworkError.value = false;
      isLoadingCurrentCompany.value = true;
      const response = await fetchCurrentCompany(companyId);
      companies.value = response.data;
    } catch (e) {
      isNetworkError.value = true;
    } finally {
      isLoadingCurrentCompany.value = false;
    }
  };

  return {
    companies,
    currentCompany,
    isLoadingCompanies,
    isLoadingCurrentCompany,
    isNetworkError,
    retrieveCompanies,
    retrieveCurrentCompany,
    addCompanyIfNotExists,
  };
}
