import { getHeader, newCreateUserUrl, getUserUrl, personalDoctorPatientAssignmentsUrl } from '@/config/config';
import axios from 'axios';

export const createUser = async user => {
  const response = await axios.post(newCreateUserUrl, user, {
    headers: getHeader(),
  });

  return response.data;
};

export const getUserByEmail = async email => {
  const url = new URL(getUserUrl);
  const params = {
    email: email,
  };

  url.search = new URLSearchParams(params).toString();

  const response = await axios.get(url, {
    headers: getHeader(),
  });

  return response.data.data[0].id;
};
